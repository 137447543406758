<template>
  <v-row
      align="center"
      justify="center"
      align-content="start"
    >
      <v-col cols="12" sm="12" md="12" lg="2" align-self="start" class="d-inline-flex pa-2 pl-7">
        <router-link to="/">
          <v-card
          elevation="0"
          style="display: inline-block; width: 50px; background-color: transparent"
          >
            <v-img src="../images/keebbear.png" height="40" class="mt-1" style="width: 50px; height: auto"></v-img>
          </v-card>
        </router-link>
        <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>

      </v-col>

      <v-col class="d-none d-md-inline-block" cols="auto" lg="10" style="text-align: right">
        <v-btn class="d-none d-md-inline-block" disabled style="color: white; font-size: 16px; text-transform: none;">Keyboard Workshop</v-btn>
        <router-link to="/TypingBear" style="text-decoration: none" class="d-none d-md-inline-block"><v-btn style="color: white; font-size: 16px; text-transform: none;" @click="this.START()">Typing Bear</v-btn></router-link>
      </v-col>


    </v-row>
</template>

<script>
import { mapMutations } from 'vuex'
export default {
  data: () => ({
    icons: [
      'mdi-facebook',
      'mdi-twitter',
      'mdi-linkedin',
      'mdi-instagram'
    ]
  }),
  methods: {
    ...mapMutations([
      'START', 'STOP', 'NEXT_PLEASE', 'PAUSE', 'RESUME'
    ])
  }
}
</script>

<style lang="scss">

</style>
