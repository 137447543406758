<template>
  <v-app :style="{background: $vuetify.theme.themes[theme].primarybackground}" class="app px-md-10">
    <v-app-bar app dark rounded flat :style="{background: $vuetify.theme.themes[theme].primarybackground}" class="app-bar px-md-11 py-md-2">
      <v-row
      align="center"
      justify="center"
      align-content="start"
      class="app-bar-row"
    >
      <v-col cols="6" sm="6" md="6" lg="2" align-self="start" class="d-inline-flex pl-8 pt-4 ma-0">
        <router-link to="/">
          <!-- <v-card elevation="0" style="display: inline-block; background-color: transparent" class="app-logo"> -->
            <v-img src="../src/images/keebbear.png" class="app-logo"></v-img>
          <!-- </v-card> -->
        </router-link>
      </v-col>

      <v-col class="d-inline-block d-md-none pa-0 pr-5" cols="6" sm="6" md="6" lg="10" style="text-align: right">
        <v-app-bar-nav-icon color="#fbf1c7" class="d-inline-block d-md-none" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      </v-col>

      <v-col class="d-none d-md-inline-block" cols="auto" lg="10" style="text-align: right">
        <v-btn class="btn-header d-none d-md-inline-block" disabled style="color: #fbf1c7; font-size: 16px; text-transform: none;">Keyboard Workshop</v-btn>
        <router-link to="/TypingBear" style="text-decoration: none" class="d-none d-md-inline-block"><v-btn class="btn-header" style="color: #fbf1c7; font-size: 16px; text-transform: none;" @click="this.START()">Typing Bear</v-btn></router-link>
      </v-col>
    </v-row>
    </v-app-bar>
    <v-navigation-drawer
      dark
      v-model="drawer"
      class="d-inline-block d-md-none"
      right
      location="end"
      color="#1d2021"
    >
      <v-list
        nav
        dense
      >
        <v-list-item-group
          v-model="group"
          active-class="deep-purple--text text--accent-4"
        >
          <v-list-item>
            <!-- <v-list-item-title>Foo</v-list-item-title> -->
            <v-btn dark elevation="0" class="d-inline-block btn-header" style="font-size: 16px; text-transform: none; background-color: #1d2021; opacity: .5;">Keyboard Workshop</v-btn>
          </v-list-item>

          <v-list-item>
            <!-- <v-list-item-title>Bar</v-list-item-title> -->
            <router-link to="/TypingBear" style="text-decoration: none; color: #fbf1c7" class="d-inline-block">
              <v-btn dark elevation="0" class="btn-header" style="font-size: 16px; text-transform: none; background-color: #1d2021;" @click="this.START()">Typing Bear</v-btn>
            </router-link>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <v-main class="main">
      <v-container fluid style="display: flex; height: 100%" class="ma-0 pa-0">
        <router-view />
      </v-container>
    </v-main>
    <v-footer app padless rounded flat :style="{background: $vuetify.theme.themes[theme].primarybackground}" class="pa-0 ma-0" height="30">
      <FooterItem/>
    </v-footer>
  </v-app>
</template>

<script>
import expressions from './expressions'
import shuffle from 'lodash.shuffle'
import FooterItem from './components/FooterItem.vue'
import HeaderItem from './components/HeaderItem.vue'
import { mapMutations } from 'vuex'

export default {
  name: 'App',
  components: {
    FooterItem,
    HeaderItem
  },
  data: () => ({
    drawer: false,
    group: null
  }),
  methods: {
    ...mapMutations([
      'START', 'STOP', 'NEXT_PLEASE', 'PAUSE', 'RESUME'
    ])
  },
  computed: {
    links () {
      return shuffle([

      ])
    },
    theme () {
      return (this.$vuetify.theme.dark) ? 'dark' : 'light'
    }
  },
  watch: {
    group () {
      this.drawer = false
    }
  },
  mounted () {
    this.$store.commit('SET_EXPRESSION', shuffle(expressions))
    this.$store.commit('SET_RANDOM_INDEX')
  }
}
</script>

<style lang="scss">
@import url('https://cdn.rawgit.com/jmnote/IropkeBatang/master/iropkebatang.css');
@import url("https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.5/dist/web/static/pretendard-dynamic-subset.css");
@import url('//cdn.jsdelivr.net/gh/neodgm/neodgm-pro-webfont@1.020/neodgm_pro/style.css');

$base-color: #fbf1c7;
$light-base-color: #a89984;
$light-base-color2: #d5c4a1;
$brand-color: #fabd2f;
$warning-color: #cc241d;
$contrast-color: #665c54;
$background-color: #1d2021;

$brand-font: 'Ubuntu', sans-serif;
$neodgm-font: 'NeoDunggeunmo pro', sans-serif;
$serif-font: 'Iropke Batang', 'Iropke Batang', 'Noto Sans KR', 'Apple SD Gothic Neo', 'Malgun Gothic', serif;
$sanserif-font: 'Pretendard', sans-serif;
$base-font: 'Abel', serif;

$base-font-size: 24px;
$big-font-size: 32px;
$small-font-size: 16px;

$bold-weight: 400;
  #app, .app {
        width: 100%;
        font-family: 'NeoDunggeunmo pro', sans-serif;
        font-display: swap;

    }
    .app-bar {
      @media all and (max-width: 600px) {
        border-bottom: 2px solid rgb(20, 20, 20);
      }
    }
    .main {
        width: 100%;
    }
    .app-logo {
      width: 50px;
      height: auto;

      @media all and (max-width: 600px) {
        width: 30px;
        height: auto;
      }
    }
    .v-snackbar__content, .v-snackbar__actions {
          font-family: $neodgm-font;
          font-size: $small-font-size;

          .v-btn {
            font-size: $small-font-size;
          }
        }
  .btn-header span {
        color: $base-color !important;
      }
</style>
