<template>
    <v-card
    :color="$vuetify.theme.themes[theme].primarybackground"
    class="container-cpm pixelBorder pixelBorder--2"
  >
    <v-expand-transition>
      <div v-show="show">
        <span class="label mr-1">최고</span><span class="count mr-8">{{ this.maxCPM }}</span>
        <span class="label mr-1">현재</span><span class="count">{{ currCPMText }}</span>
        <div class="sparkline">
          <trend
            v-model="inputCPMs"
            :data="this.CPMs"
            :gradient="['#fabd2f', '#b8bb26', '#83a598']"
            auto-draw
            smooth
            :width="800"
            :height="150"
          >
          </trend>
        </div>
      </div>
    </v-expand-transition>
    <v-card-title style="font-size: 16px"><v-icon class="mr-2 icon">mdi-chart-line-variant</v-icon>분당 타자수 (CPM)</v-card-title>
    <v-card-actions style="position: absolute; bottom: 4px; right: 0">
      <!-- <v-btn
        color="orange-lighten-2"
        variant="text"
      >
      </v-btn> -->
      <v-spacer></v-spacer>
      <v-btn
        :icon="show ? 'mdi-chevron-down' : 'mdi-chevron-up'"
        @click="show = !show"
      ></v-btn>
    </v-card-actions>
    <v-card-subtitle></v-card-subtitle>
  </v-card>
</template>

<script>
export default {
  model: {
    prop: 'maxCPM, CPMs'
  },
  props: {
    maxCPM: {
      type: Number,
      default () {
        return 0
      }
    },
    CPMs: {
      type: Array,
      default () {
        return []
      }
    }
  },
  data: () => ({
    show: false
  }),
  mounted () {},
  computed: {
    theme () {
      return (this.$vuetify.theme.dark) ? 'dark' : 'light'
    },
    inputMaxCPM: {
      get () {
        return this.maxCPM
      }
    },
    inputCPMs: {
      get () {
        return this.CPMs
      }
    },
    currCPMText () {
      return this.CPMs[this.CPMs.length - 1] || 0
    }
  },
  methods: {}
}
</script>

<style lang="scss">
$base-color: #fbf1c7;
$light-base-color: #a89984;
$light-base-color2: #d5c4a1;
$brand-color: #fabd2f;
$warning-color: #cc241d;
$contrast-color: #665c54;
$background-color: #1d2021;
$background-color2: #282828;

$base-font-size: 24px;
$big-font-size: 32px;
$small-font-size: 16px;

@mixin pixelBorder($backgroundColor, $borderColor, $borderWidth: 2px) {
    box-shadow:
      // Background Color
      0             (-$borderWidth * 3)  0 (-$borderWidth)     $backgroundColor,
      0              ($borderWidth * 3)  0 (-$borderWidth)     $backgroundColor,
      0             (-$borderWidth * 6)  0 (-$borderWidth * 2) $backgroundColor,
      0              ($borderWidth * 6)  0 (-$borderWidth * 2) $backgroundColor,
      0             (-$borderWidth * 9)  0 (-$borderWidth * 4) $backgroundColor,
      0              ($borderWidth * 9)  0 (-$borderWidth * 4) $backgroundColor,
      0             (-$borderWidth * 12) 0 (-$borderWidth * 6) $backgroundColor,
      0              ($borderWidth * 12) 0 (-$borderWidth * 6) $backgroundColor,
      // Border
    (-$borderWidth)   0                  0   0                 $borderColor,
    ($borderWidth)   0                  0   0                 $borderColor,
      0             (-$borderWidth * 2)  0   0                 $borderColor,
      0              ($borderWidth * 2)  0   0                 $borderColor,
      0             (-$borderWidth * 5)  0 (-$borderWidth)     $borderColor,
      0              ($borderWidth * 5)  0 (-$borderWidth)     $borderColor,
      0             (-$borderWidth * 7)  0 (-$borderWidth * 2) $borderColor,
      0              ($borderWidth * 7)  0 (-$borderWidth * 2) $borderColor,
      0             (-$borderWidth * 10) 0 (-$borderWidth * 4) $borderColor,
      0              ($borderWidth * 10) 0 (-$borderWidth * 4) $borderColor,
      0             (-$borderWidth * 13) 0 (-$borderWidth * 6) $borderColor,
      0              ($borderWidth * 13) 0 (-$borderWidth * 6) $borderColor;
  }

  .pixelBorder {
    transform: translateZ(0); // Safari fix

    &--2 {
      @include pixelBorder($background-color, $contrast-color);
    }

    &--4 {
      @include pixelBorder($background-color, $contrast-color, 4px);
    }

    &--6 {
      @include pixelBorder($background-color, $contrast-color, 6px);
    }

    &--10 {
      @include pixelBorder($background-color, $contrast-color, 10px);
    }
  }

.container-cpm{
  position: absolute;
  bottom: 20px;
  width: 100%;
  max-width: 800px;
  padding: 0px 20px 10px 20px;
  border-radius: 0;
  // border-bottom: none;
  color: $base-color;
  background-color: rgba(29, 32, 33, 0.5);
  z-index: 2;

  .count {
    font-size: 32px;
    color: $base-color;
  }

  .label {
    font-size: 16px;
    color: $light-base-color;
  }

  .icon {
    font-size: 20px;
    vertical-align: text-bottom;
  }

  .sparkline {
          min-height: 100px;

          svg {
            stroke-width: 5px;
          }
        }
}
</style>
