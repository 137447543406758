<template>
  <v-row
      align="center"
      justify="center"
    >
      <!-- <v-card-title>
        <v-spacer></v-spacer>

        <v-btn
          v-for="icon in icons"
          :key="icon"
          class="mx-4"
          dark
          icon
        >
          <v-icon size="24px">
            {{ icon }}
          </v-icon>
        </v-btn>
      </v-card-title> -->

        <span class="credit"><span class="created-by mr-1"> created by</span>{{ 'KEEBBEAR' }}, {{ new Date().getFullYear() }}</span>
    </v-row>
</template>

<script>
export default {
  data: () => ({
    icons: [
      'mdi-facebook',
      'mdi-twitter',
      'mdi-linkedin',
      'mdi-instagram'
    ]
  })
}
</script>

<style lang="scss">
$base-color: #fbf1c7;
$light-base-color: #a89984;
$light-base-color2: #d5c4a1;
$brand-color: #fabd2f;
$warning-color: #cc241d;
$contrast-color: #665c54;
$background-color: #1d2021;
$background-color2: #3c3836;

$base-font-size: 24px;
$big-font-size: 32px;
$small-font-size: 16px;
    span.credit {
        display: flex;
        text-align: center;
        color: $contrast-color;
        span.created-by {
            color: $background-color2;
            font-size: $small-font-size;
        }
    }
</style>
