<template>
    <div class="app__window">
        <TypingWindow />
    </div>
</template>

<script>
import TypingWindow from './TypingWindow'
// import TypeInput from './TypeInput'
// import Countdown from './Countdown'
import { mapState } from 'vuex'

export default {
  components: {
    TypingWindow
  },
  computed: {
    ...mapState([
      'isRunning'
    ])
  }
}
</script>

<style lang="scss">
    .app__window {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        .help {
            margin: 20px 0 0;
            color: #bbbbbb;
            list-style: none;
            padding: 0;
            transition: color 0.4s;

            &:hover {
               color: #000;
            }
            li {
                margin-bottom: 10px;

                code {
                    background-color: #ddd;
                    padding: 2px 5px;
                    border-radius: 4px;
                }
            }
        }
    }
</style>
