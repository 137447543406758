// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'

// Vuetify
import { createVuetify } from 'vuetify'

export default createVuetify({
  theme: {
    themes: {
      light: {
        primary: '#ebdbb2',
        secondary: '#fbf1c7',
        accent: '#8c9eff',
        error: '#b71c1c',
        primarybackground: '#1d2021',
        secondarybackground: '#eeeeee'
      }
    }
  }
})
