function quote (str, delimiter) {
  return (str + '')
    .replace(new RegExp('[.\\\\+*?\\[\\^\\]$(){}=!<>|:\\' + (delimiter || '') + '-]', 'g'), '\\$&')
}

function strComparison (fullString, typedString) {
  const regx = new RegExp(`^${quote(typedString, '/')}.*$`)
  return regx.test(fullString)
}

export default strComparison
