<template>
    <div class="typing__window" @click="reFocus">
        <div v-if="isRunning" class="awaited-word">
          <span v-for="(char, order) in vocabularyCharacters" :key="order" class="word" :style="char == '\n' ? 'display: inline-flex; min-width: 100%; height: 0' : 'display: inline-block'">
          <div v-if="char == '\n'" style="display: inline-flex; min-width: 100%;">
            <span class="awaited-letter" v-for="(txt, index) in char" :key="index" ref="letterToType" @click="clickLetter">
              <span class="enter-letter">{{ '\n' }}</span>
              <span class="error-letter" ref="errorType"></span>
              <span class="ing-letter" ref="ingType"></span>
            </span>
          </div>
          <span v-else class="awaited-letter" v-for="(txt, index) in char" :key="index" ref="letterToType" @click="clickLetter">
              <span>{{ txt }}</span>
              <span class="error-letter" ref="errorType"></span>
              <span class="ing-letter" ref="ingType"></span>
            </span>
          </span>
          <div class="container-bibliographic">
            <span class="bibliographic">{{ author }}</span><span v-if="author">{{ ', ' }}</span>
            <span v-if="title">{{ '〈' }}</span><span class="bibliographic">{{ title }}</span><span v-if="title">{{ '〉' }}</span>
            <span v-if="year">{{ ' , ' }}</span><span class="bibliographic">{{ year }}</span>
          </div>
        </div>

        <div v-else style="width: 100%; text-align: center">
          <v-btn color="#282828" class="btn-start pixelBorder pixelBorder--2" @click="this.START()"><v-img src="../images/logo_typingbear.png" height="80" class="mx-auto" style="width: 202px"></v-img></v-btn>
        </div>
            <textarea :maxlength="displayCharacters.length" autofocus spellcheck="false" :value="entry" placeholder="Start Typing" type="text" class="input" ref="gameInput"
            @input="compareInputToExpected" @input.once="setTimer()" @focus="focusInput"  @blur="blurInput" @keyup="detectCursor"></textarea>
    </div>
    <TypingCPM v-if="isRunning"
      v-model:CPMs="this.CPMs"
      v-model:maxCPM="this.maxCPM"
    />
    <v-snackbar
      color="red accent-1"
      v-model="snackbar"
    >
      {{ '어딘가 틀린 부분이 있어요' }}
      <template v-slot:actions>
        <v-btn
          color="#cc241d"
          variant="text"
          class="snackbar"
          @click="snackbar = false"
        >
          닫기
        </v-btn>
      </template>
    </v-snackbar>
    <!-- <input ref="hiddenInput"> -->
</template>

<script>
import { mapGetters, mapState, mapMutations } from 'vuex'
import TypingCPM from './TypingCPM.vue'
import Hangul from 'hangul-js'

export default {
  components: {
    TypingCPM
  },
  data () {
    return {
      keyword: '',
      entry: '',
      wordToTypeIndex: 0,
      letterToTypeIndex: 0,
      capsStatus: false,
      inputKey: 0,
      isStart: this.$store.state.isRunning,
      now: '',
      hasErrors: false,
      selectionStart: 1,
      selectionEnd: 0,
      CPMs: [],
      maxCPM: 0,
      timer: 1,
      numWords: 0,
      index: 0,
      currentTop: 0,
      currentLeft: 0,
      snackbar: false
    }
  },
  mounted () {
    // this.stylizeWithClass(this.$refs.letterToType[this.letterToTypeIndex], true, 'letter-to-type')

    // console.warn(this.displayCharacters)

    // for (let index = 0; index < this.displayCharacters.length; index++) {
    //   console.log(this.displayCharacters[index])
    // }
    this.reFocus()
  },
  updated () {
    // console.log(letterToType)
    // console.log(typingLetter)
  },
  methods: {
    ...mapMutations([
      'START', 'STOP', 'NEXT_PLEASE', 'PAUSE', 'RESUME'
    ]),
    reFocus () {
      this.$refs.gameInput.focus()
    },
    clickLetter (e) {
      this.$refs.gameInput.focus()
    },
    focusInput (e) {
      const toTypeLetter = document.getElementsByClassName('awaited-letter')
      setTimeout(function () {
        this.selectionStart = e.target.selectionStart
        switch (this.selectionStart) {
          case 0:
            if (toTypeLetter[this.selectionStart]) toTypeLetter[this.selectionStart].classList.remove('letter-to-type')
            if (toTypeLetter[this.selectionStart]) toTypeLetter[this.selectionStart].classList.add('letter-to-type')
            break
          case 1:
            toTypeLetter[this.selectionStart].classList.remove('letter-to-type')
            toTypeLetter[this.selectionStart - 1].classList.add('letter-to-type')
            break
          default:
            toTypeLetter[this.selectionStart].classList.remove('letter-to-type')
            toTypeLetter[this.selectionStart - 1].classList.add('letter-to-type')
            toTypeLetter[this.selectionStart - 2].classList.remove('letter-to-type')
        }
      }, 0)
    },
    blurInput (e) {
      const toTypeLetter = document.getElementsByClassName('awaited-letter')
      setTimeout(function () {
        this.selectionStart = e.target.value.length
        switch (this.selectionStart) {
          case 0:
            if (toTypeLetter[this.selectionStart]) toTypeLetter[this.selectionStart].classList.remove('letter-to-type')
            break
          case 1:
            if (toTypeLetter[this.selectionStart - 1]) toTypeLetter[this.selectionStart - 1].classList.remove('letter-to-type')
            break
          default:
            if (toTypeLetter[this.selectionStart - 1]) toTypeLetter[this.selectionStart - 1].classList.remove('letter-to-type')
        }
      }, 0)
    },
    detectCursor (e) {
      const toTypeLetter = document.getElementsByClassName('awaited-letter')
      const displayCharactersLength = this.displayCharacters.length

      setTimeout(function () {
        this.selectionStart = e.target.selectionStart

        if (e.target.value) {
          for (let index = 0; index < displayCharactersLength; index++) {
            switch (this.selectionStart) {
              case 0:
                toTypeLetter[index].classList.remove('letter-to-type')
                toTypeLetter[this.selectionStart].classList.add('letter-to-type')
                break

              default:
                // if (e.target.value[this.selectionStart].indexOf('\n') === 0 || e.target.value[this.selectionStart].indexOf(' ') === 0) {
                //   toTypeLetter[index].classList.remove('letter-to-type')
                //   toTypeLetter[this.selectionStart].classList.add('letter-to-type')
                // } else {
                toTypeLetter[index].classList.remove('letter-to-type')
                // if (e.target.value[this.selectionStart - 1] === '\n' || e.target.value[this.selectionStart - 1] === ' ') {
                if (e.target.value[this.selectionStart - 1] === '\n') {
                  toTypeLetter[this.selectionStart].classList.add('letter-to-type')
                } else {
                  toTypeLetter[this.selectionStart - 1].classList.add('letter-to-type')
                }
                // }
            }
          }
        }
      }, 0)
    },
    detectClear (e) {
      const toTypeLetter = document.getElementsByClassName('awaited-letter')
      const errorLetter = document.getElementsByClassName('error-letter')
      for (let index = 0; index < this.displayCharacters.length; index++) {
        this.hasErrors = false
        if (errorLetter[index].classList.contains('has-error-letter') || toTypeLetter[index].classList.contains('space-error') || toTypeLetter[index].classList.contains('enter-error')) {
          this.hasErrors = true
          break
        }
      }
    },
    detectCurrentCursor (e) {
      const toTypeLetter = document.getElementsByClassName('awaited-letter')
      let currentElement

      for (let index = 0; index < this.displayCharacters.length; index++) {
        if (toTypeLetter[index].classList.contains('letter-to-type')) {
          currentElement = toTypeLetter[index]
        }
      }

      this.currentTop = currentElement.getBoundingClientRect().top
      this.currentLeft = currentElement.getBoundingClientRect().left
    },
    openSnackbar (e) {
      this.snackbar = true
    },
    compareInputToExpected (e) {
      this.entry = e.target.value
      // console.error(e.target.value)
      // console.error(this.displayCharacters)
      this.$store.state.input = e.target.value
      this.numWords = flatten(this.disassembleText.slice(0, document.getElementsByClassName('letter-found').length)).length

      function flatten (value) {
        return Object.prototype.toString.call(value) === '[object Array]'
          ? [].concat.apply([], value.map(flatten))
          : value
      }

      // this.detectCurrentCursor()

      this.pushCPM()
      // disassemble for Korean
      // const this.disassembleText = Hangul.disassemble(this.displayCharacters, true)
      const disassembleInput = Hangul.disassemble(this.entry, true)

      // console.log(this.displayCharacters.length)
      // console.log(this.entry.length)
      // console.log(this.hasErrors)

      // elements
      const toTypeLetter = document.getElementsByClassName('awaited-letter')
      const errorLetter = document.getElementsByClassName('error-letter')
      const typingLetter = document.getElementsByClassName('ing-letter')

      for (let index = this.index; index < this.disassembleText.length; index++) {
        if (e.target.value) {
          if (this.displayCharacters[e.target.selectionStart - 1].indexOf('\n') === 0 || this.displayCharacters[e.target.selectionStart - 1].indexOf(' ') === 0) {
            toTypeLetter[index].classList.remove('letter-to-type')
            toTypeLetter[e.target.selectionStart].classList.add('letter-to-type')
          } else if (e.target.selectionStart === this.disassembleText.length) {
            toTypeLetter[index].classList.remove('letter-to-type')
            toTypeLetter[e.target.selectionStart - 1].classList.add('letter-to-type')
          } else if (e.target.selectionStart === 1) {
            toTypeLetter[index].classList.remove('letter-to-type')
            toTypeLetter[e.target.selectionStart - 1].classList.add('letter-to-type')
          } else if (e.target.selectionStart >= 2) {
            toTypeLetter[index].classList.remove('letter-to-type')
            toTypeLetter[e.target.selectionStart - 1].classList.add('letter-to-type')
          } else {
            toTypeLetter[index].classList.remove('letter-to-type')
            toTypeLetter[e.target.selectionStart].classList.add('letter-to-type')
          }
        } else {
          toTypeLetter[index].classList.remove('letter-to-type')
          toTypeLetter[e.target.selectionStart].classList.add('letter-to-type')
        }

        if (disassembleInput[index]) { // 사용자가 입력한 값이 있음
          if (JSON.stringify(this.disassembleText[index]) === JSON.stringify(disassembleInput[index])) {
            if (e.target.value[index]) {
              typingLetter[index].innerText = e.target.value[index]
              // typingLetter[index].classList.add('has-ing-letter')
            }
            if (e.target.value[index] === '\n') {
              toTypeLetter[index].classList.add('letter-found')
            }
            toTypeLetter[index].classList.add('letter-found')
            toTypeLetter[index].classList.remove('space-error')
            toTypeLetter[index].classList.remove('enter-error')
            errorLetter[index].classList.remove('has-error-letter')
            typingLetter[index].classList.remove('has-ing-letter')

            errorLetter[index].innerText = ''

            // 주어진 글자를 다 입력함
            if (this.displayCharacters.length === e.target.value.length &&
            this.disassembleText[this.disassembleText.length - 1].length === disassembleInput[disassembleInput.length - 1].length) {
              this.detectClear()
              if (!this.hasErrors) {
                // console.log('That was perfect typing')
                typingLetter[index].innerText = ''
                errorLetter[index].innerText = ''
                e.target.value = ''
                this.NEXT_PLEASE()
                this.entry = ''
                this.$refs.gameInput.value = null
                document.getElementsByClassName('input')[0].blur()
                document.getElementsByClassName('input')[0].focus()
                setTimeout(function () {
                  e.target.value = ''
                  this.entry = ''
                  typingLetter[index].innerText = ''
                  errorLetter[index].innerText = ''
                  toTypeLetter[index].classList.remove('space-error')
                  toTypeLetter[index].classList.remove('enter-error')
                  errorLetter[index].classList.remove('has-error-letter')
                  typingLetter[index].classList.remove('has-ing-letter')
                  document.getElementsByClassName('input')[0].blur()
                  document.getElementsByClassName('input')[0].focus()
                }, 0)
                this.$nextTick(function () {
                  this.compareInputToExpected(e)
                })
              } else {
                this.openSnackbar()
              }
            }
          } else {
            if (!disassembleInput[index]) { // 입력 값이 없음
              toTypeLetter[index].classList.remove('letter-found')
              errorLetter[index].classList.remove('has-error-letter')
              typingLetter[index].classList.remove('has-ing-letter')
              errorLetter[index].innerText = ''
              typingLetter[index].innerText = ''
            } else if (Hangul.search(this.disassembleText[index], disassembleInput[index]) === -1) { // 입력값이 있지만, 자소 분리를 했는데 틀렸음
              if (e.target.value[index] && (this.disassembleText[index].indexOf(' ') !== 0 && this.disassembleText[index].indexOf('\n') !== 0)) { // 입력값이 있으면 화면에 그려줌 // 공백이나 엔터면 제외
                typingLetter[index].innerText = e.target.value[index]
                typingLetter[index].classList.add('has-ing-letter')
              }
              // 현재 입력 글자의 마지막 자소가 뒷 글자의 첫 자소와 같음
              if (this.disassembleText[index + 1] && disassembleInput[index].length > 1 && disassembleInput[index][disassembleInput[index].length - 1] === this.disassembleText[index + 1][0]) {
                if (!this.$refs.letterToType[index].classList.contains('letter-found')) { // 마지막 자소 입력 전에 이미 완성됨 (자소가 두개)
                  toTypeLetter[index].classList.add('letter-error')
                  this.errorStyle = window.setTimeout(this.stylizeWithClass, 100, toTypeLetter[index], false, 'letter-error')
                } else if (disassembleInput[index + 1]) {
                  errorLetter[index].innerText = e.target.value[index]
                  errorLetter[index].classList.add('has-error-letter')
                }
                // this.hasErrors = true
              // 현재 입력 글자의 마지막 자소가 뒷 글자의 첫 자소와 다름
              } else {
                if (this.disassembleText[index].indexOf(' ') === 0) { // 현재 공백일 때
                  // this.hasErrors = true
                  toTypeLetter[index].classList.add('space-error')
                } else if (this.disassembleText[index].indexOf('\n') === 0) { // 현재 엔터일 때
                  toTypeLetter[index].classList.add('enter-error')
                } else {
                  // this.hasErrors = true
                  this.errorStyle = window.setTimeout(this.stylizeWithClass, 100, toTypeLetter[index], false, 'letter-error')
                  toTypeLetter[index].classList.add('letter-error')
                  errorLetter[index].innerText = e.target.value[index]
                  errorLetter[index].classList.add('has-error-letter')
                  if (disassembleInput[index].indexOf(' ') === 0 || disassembleInput[index].indexOf('\n') === 0) {
                    errorLetter[index].innerText = this.displayCharacters[index]
                    errorLetter[index].classList.remove('has-error-letter')
                  }
                }
              }
            } else { // 자소 분리를 했는데 맞는 글자임
              if (this.disassembleText[index][0] !== disassembleInput[index][0]) {
                errorLetter[index].innerText = e.target.value[index]
                errorLetter[index].classList.add('has-error-letter')
              }
              if (e.target.value[index]) {
                typingLetter[index].innerText = e.target.value[index]
                typingLetter[index].classList.add('has-ing-letter')
                errorLetter[index].innerText = ''
                errorLetter[index].classList.remove('has-error-letter')
              }
            }
          }
        } else {
          toTypeLetter[index].classList.remove('letter-found')
          toTypeLetter[index].classList.remove('space-error')
          toTypeLetter[index].classList.remove('enter-error')

          errorLetter[index].classList.remove('has-error-letter')
          errorLetter[index].innerText = ''

          typingLetter[index].classList.remove('has-ing-letter')
          typingLetter[index].innerText = ''
        }
      }
    },
    resetClass () {
      const toTypeLetter = document.getElementsByClassName('awaited-letter')
      const errorLetter = document.getElementsByClassName('error-letter')
      for (let index = 0; index < this.displayCharacters.length; index++) {
        // toTypeLetter[index].classList.remove('letter-found')
        toTypeLetter[index].classList.remove('space-error')
        toTypeLetter[index].classList.remove('enter-error')
        errorLetter[index].classList.remove('has-error-letter')
        errorLetter[index].innerText = ''
      }
    },

    stylizeWithClass (element, shouldAdd, styleClass) {
      if (element) {
        if (shouldAdd) {
          element.classList.add(styleClass)
        } else {
          element.classList.remove(styleClass)
        }
      }
    },

    setTimer () {
      setInterval(() => {
        this.timer += 1
      }, 1000)
    },

    pushCPM () {
      const CPM = Math.round((this.numWords / this.timer) * 60)
      this.CPMs.push(CPM)
      this.CPMs = this.CPMs.slice(-50, this.CPMs.length)
      if (this.maxCPM < CPM) this.maxCPM = CPM
    }
  },
  computed: {
    ...mapGetters([
      'hasError',
      'typeWorldLength',
      'displayCharacters',
      'disassembleText',
      'vocabularyCharacters'
    ]),
    ...mapState(['title', 'author', 'year', 'word', 'input', 'isRunning']),
    summedCPMs () {
      const allCPMs = this.CPMs.reduce((acc, CPM) => acc + CPM, 0)
      return Math.round(allCPMs / this.CPMs.length)
    },
    getDisassembleText: function () {
      return Hangul.disassemble(this.displayCharacters, true)
    }
  },
  watch: {
    input (text) {
      switch (text) {
        case 's':
          this.START()
          break
        case 'stop':
          this.STOP()
          break
        case 'pause':
          this.PAUSE()
          break
        case 'resume':
          this.RESUME()
          break
        case 'next':
          this.NEXT_PLEASE()
          break
        // default:
        //   if (text.length && text.length === this.displayCharacters.length) {
        //     this.NEXT_PLEASE()
        //   }
      }
    }
  }
}
</script>

<style lang="scss">
$base-color: #fbf1c7;
$light-base-color: #a89984;
$light-base-color2: #d5c4a1;
$brand-color: #fabd2f;
$green-color: #b8bb26;
$warning-color: #cc241d;
$contrast-color: #665c54;
$background-color: #1d2021;
$background-color2: #282828;

$brand-font: 'Ubuntu', sans-serif;
$neodgm-font: 'NeoDunggeunmo pro', sans-serif;
$serif-font: 'Iropke Batang', 'Iropke Batang', 'Noto Sans KR', 'Apple SD Gothic Neo', 'Malgun Gothic', serif;
$sanserif-font: 'Pretendard', sans-serif;
$base-font: 'Abel', serif;

$base-font-size: 24px;
$big-font-size: 32px;
$small-font-size: 16px;

$bold-weight: 400;

    @keyframes splat {
        0% {font-size: $big-font-size; color: $warning-color;}
        30% {font-size: calc(#{$big-font-size} + 0.3rem); color: $warning-color;}
        80% {font-size: calc(#{$big-font-size} - 0.1rem); color: $warning-color;}
        100% {font-size: $big-font-size; color: $warning-color;}
    }

    @keyframes splatCorrect {
        0% {font-size: $big-font-size; color: $light-base-color;}
        30% {font-size: calc(#{$big-font-size} + 0.3rem); color: $light-base-color;}
        80% {font-size: calc(#{$big-font-size} - 0.1rem); color: $base-color;}
        100% {font-size: $big-font-size; color: $base-color;}
    }

    @keyframes countdown-pulsate {
        0% {color: $warning-color;}
        80% {color: $contrast-color;}
    }

    @keyframes cursor-pulse {
        0% {border-right: 3px solid $light-base-color;  border-bottom: 3px solid $light-base-color;}
        49% {border-right: 3px solid $light-base-color;  border-bottom: 3px solid $light-base-color;}
        50% {border-right: 3px solid $base-color;  border-bottom: 3px solid $base-color;}
        99% {border-right: 3px solid $base-color;  border-bottom: 3px solid $base-color;}
    }

    .typing__window {
        width: 80%;
        max-width: 1000px;

         @media all and (max-width: 500px) {
            width: 100%;
            padding: 0 20px;
          }

        .expression {
            padding: 10px;
            transition: background-color 0.5s;
            span {
                &:first-child {
                    padding-left: 10px;
                    border-bottom-left-radius: 4px;
                    border-top-left-radius: 4px;
                }
                &:last-child {
                    padding-right: 10px;
                    border-bottom-right-radius: 4px;
                    border-top-right-radius: 4px;
                }
            }
            .typed {
                color: #fff;
                background-color: #00b894;
            }
        }
        .has-error {
            .typed {
                background-color: #e17055;
            }
        }
        .awaited-word {
            display: inline-block;
            position: relative;
            margin: 0;

            .word {
              display: inline-block;
              position: relative;
            }

            .awaited-letter {
                display: table-cell;
                font-family: $neodgm-font;
                font-weight: $bold-weight;
                font-size: $big-font-size;
                color: $contrast-color;
                position: relative;
                top: 0;
                bottom: 0;
                min-width: 0.6rem;
                z-index: 1;

                @media all and (max-width: 500px) {
                  font-size: $base-font-size;
                }
            }

            .enter-letter::after {
              content: "";
              position: absolute;
              top: -30px;
              left: -8px;
              width: 30px;
              height: 30px;
            }

            .error-letter {
                font-family: $neodgm-font;
                font-weight: $bold-weight;
                font-size: $big-font-size;
                color: $warning-color;
                position: absolute;
                top: 0px;
                left: 0;
                right: 0;
                bottom: 0;
                z-index: 3;

                @media all and (max-width: 500px) {
                  font-size: $base-font-size;
                }
            }
            .ing-letter {
                font-family: $neodgm-font;
                font-weight: $bold-weight;
                font-size: $big-font-size;
                color: $base-color;
                position: absolute;
                top: 0px;
                left: 0;
                right: 0;
                bottom: 0;
                z-index: 2;

                @media all and (max-width: 500px) {
                  font-size: $base-font-size;
                }
            }

            .space-error {
                border-bottom: 2px solid $warning-color;
            }

            .enter-error {
                border-bottom: 2px solid $warning-color;
            }

            .has-ing-letter {
                background-color: $background-color;
                animation: splatCorrect 0.2s 1;
            }

            .has-error-letter {
                background-color: $background-color;
                animation: splat 0.2s 1;
            }

            .entry-letter {
                font-family: $brand-font;
                font-weight: $bold-weight;
                font-size: $big-font-size;
                position: absolute;
                left: 400px;
                background-color: transparent;
                color: $light-base-color;
                pointer-events: none;

               @media all and (max-width: 500px) {
                  font-size: $base-font-size;
                }
            }

            .caps-indicator {
                position: relative;
                left: 1rem;
                width: 0;
                height: 30px;
                text-align: center;

                i {
                    position: absolute;
                    bottom: 0;
                }

                i:first-child {
                    color: $light-base-color;
                }

                i:nth-child(2) {
                    color: $base-color;
                    font-size: 40%;
                    left: 3px;
                    bottom: 3px;
                }
            }

            .letter-to-type::after {
                position: absolute;
                content: '';
                width: 0.5rem;
                height: 0.5rem;
                right: -0.2rem;
                bottom: 0rem;
                border-right: 3px solid $light-base-color;
                border-bottom: 3px solid $light-base-color;
                // background-color: $light-base-color;
                animation: cursor-pulse 1s linear infinite;
                z-index: 99;
            }

            .letter-found {
                color: $light-base-color;
                transition: all 0.2s cubic-bezier(0.5,0,0,1);

                .enter-letter::after {
                  content: "";
                  position: absolute;
                  color: $base-color;
                  top: -30px;
                  left: -8px;
                  width: 30px;
                  height: 30px;
                }
            }

            .letter-error {

                // animation: splat 0.2s 1;
            }

            .container-bibliographic {
              font-size: 1rem;
              color: $light-base-color2;

              .bibliographic {
                 color: $light-base-color;
              }
            }
        }

          @mixin pixelBorder($backgroundColor, $borderColor, $borderWidth: 2px) {
            box-shadow:
              // Background Color
              0             (-$borderWidth * 3)  0 (-$borderWidth)     $backgroundColor,
              0              ($borderWidth * 3)  0 (-$borderWidth)     $backgroundColor,
              0             (-$borderWidth * 6)  0 (-$borderWidth * 2) $backgroundColor,
              0              ($borderWidth * 6)  0 (-$borderWidth * 2) $backgroundColor,
              0             (-$borderWidth * 9)  0 (-$borderWidth * 4) $backgroundColor,
              0              ($borderWidth * 9)  0 (-$borderWidth * 4) $backgroundColor,
              0             (-$borderWidth * 12) 0 (-$borderWidth * 6) $backgroundColor,
              0              ($borderWidth * 12) 0 (-$borderWidth * 6) $backgroundColor,
              // Border
            (-$borderWidth)   0                  0   0                 $borderColor,
            ($borderWidth)   0                  0   0                 $borderColor,
              0             (-$borderWidth * 2)  0   0                 $borderColor,
              0              ($borderWidth * 2)  0   0                 $borderColor,
              0             (-$borderWidth * 5)  0 (-$borderWidth)     $borderColor,
              0              ($borderWidth * 5)  0 (-$borderWidth)     $borderColor,
              0             (-$borderWidth * 7)  0 (-$borderWidth * 2) $borderColor,
              0              ($borderWidth * 7)  0 (-$borderWidth * 2) $borderColor,
              0             (-$borderWidth * 10) 0 (-$borderWidth * 4) $borderColor,
              0              ($borderWidth * 10) 0 (-$borderWidth * 4) $borderColor,
              0             (-$borderWidth * 13) 0 (-$borderWidth * 6) $borderColor,
              0              ($borderWidth * 13) 0 (-$borderWidth * 6) $borderColor;
          }

          .pixelBorder {
            transform: translateZ(0); // Safari fix

            &--2 {
              @include pixelBorder($background-color2, $light-base-color);
            }

            &--4 {
              @include pixelBorder($background-color2, $light-base-color, 4px);
            }

            &--6 {
              @include pixelBorder($background-color2, $light-base-color, 6px);
            }

            &--10 {
              @include pixelBorder($background-color2, $light-base-color, 10px);
            }
          }

          .btn-start:hover.pixelBorder {
            transform: translateZ(0); // Safari fix

            &--2 {
              @include pixelBorder($background-color2, $base-color);
            }

            &--4 {
              @include pixelBorder($background-color2, $base-color, 4px);
            }

            &--6 {
              @include pixelBorder($background-color2, $base-color, 6px);
            }

            &--10 {
              @include pixelBorder($background-color2, $base-color, 10px);
            }
          }

        .btn-start {
          font-size: 32px;
          width: 250px;
          height: 85px;
          letter-spacing: -1px;
          color: $background-color;
          border-radius: 0;
        }

        .input {
          top: 0;
          left: 0;
          position: absolute;
          opacity: 0;
        }

    }
</style>
