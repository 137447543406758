<template>
<v-container class="main-container" fill-height fluid>
  <v-row class="section-container" justify="center" align="center" no-gutters>
    <v-col class="d-flex d-sm-none section bg first" cols="12" md="12">
      <div class="d-flex d-sm-none mt-5 ml-5" style="position: absolute; flex-direction: column;">
        <div style="position: relative; font-size: 32px; display: block;">
          <div style="position: relative; display: inline-flex">
            {{ 'For' }}
          </div>
          <div style="position: absolute; width: 200px; height: 20px; background-color: black; bottom: 10px; right: -5px"></div>
          <div class="colored ml-3" style="position: relative; font-size: 48px; display: inline-flex;">
            {{ 'keyboard' }}
          </div>
        </div>
        <div style="position: relative; font-size: 32px; display: block; margin-top: -15px">
          <div style="position: relative; font-size: 32px; display: inline-flex">
            {{ 'that' }}
          </div>
          <div class="colored ml-3" style="position: relative; font-size: 32px; display: inline-flex">{{ 'suits you' }}</div>
        </div>
         <div style="position: relative; font-size: 16px">
            {{ '각자에게 맞는 자판을 위해' }}
          </div>
      </div>
    </v-col>
    <v-col class="d-none d-sm-flex section bg first" cols="12" md="12">
      <div style="position: absolute; top: 0; left: 0; width: 10px; height: 2px; background-color: #d5c4a1"></div>
      <div style="position: absolute; top: 2px; left: 0; width: 6px; height: 2px; background-color: #d5c4a1"></div>
      <div style="position: absolute; top: 4px; left: 0; width: 4px; height: 2px; background-color: #d5c4a1"></div>
      <div style="position: absolute; top: 6px; left: 0; width: 2px; height: 2px; background-color: #d5c4a1"></div>
      <div style="position: absolute; top: 8px; left: 0; width: 2px; height: 2px; background-color: #d5c4a1"></div>

      <div style="position: absolute; top: 0; right: 0; width: 10px; height: 2px; background-color: #d5c4a1"></div>
      <div style="position: absolute; top: 2px; right: 0; width: 6px; height: 2px; background-color: #d5c4a1"></div>
      <div style="position: absolute; top: 4px; right: 0; width: 4px; height: 2px; background-color: #d5c4a1"></div>
      <div style="position: absolute; top: 6px; right: 0; width: 2px; height: 2px; background-color: #d5c4a1"></div>
      <div style="position: absolute; top: 8px; right: 0; width: 2px; height: 2px; background-color: #d5c4a1"></div>

      <div style="position: absolute; bottom: 0; left: 0; width: 10px; height: 2px; background-color: #d5c4a1"></div>
      <div style="position: absolute; bottom: 2px; left: 0; width: 6px; height: 2px; background-color: #d5c4a1"></div>
      <div style="position: absolute; bottom: 4px; left: 0; width: 4px; height: 2px; background-color: #d5c4a1"></div>
      <div style="position: absolute; bottom: 6px; left: 0; width: 2px; height: 2px; background-color: #d5c4a1"></div>
      <div style="position: absolute; bottom: 8px; left: 0; width: 2px; height: 2px; background-color: #d5c4a1"></div>

      <div style="position: absolute; bottom: 0; right: 0; width: 10px; height: 2px; background-color: #d5c4a1"></div>
      <div style="position: absolute; bottom: 2px; right: 0; width: 6px; height: 2px; background-color: #d5c4a1"></div>
      <div style="position: absolute; bottom: 4px; right: 0; width: 4px; height: 2px; background-color: #d5c4a1"></div>
      <div style="position: absolute; bottom: 6px; right: 0; width: 2px; height: 2px; background-color: #d5c4a1"></div>
      <div style="position: absolute; bottom: 8px; right: 0; width: 2px; height: 2px; background-color: #d5c4a1"></div>

      <div class="d-none d-lg-block" style="position: absolute; left: 50px; bottom: 20px">
        <div style="position: relative; bottom: -80px; font-size: 80px; display: inline-block">
          {{ 'For' }}
          <div style="position: absolute; width: 480px; height: 50px; background-color: black; bottom: 40px; right: -12px"></div>
          <div class="colored" style="position: relative; font-size: 112px; display: inline-block">
            {{ 'keyboard' }}
          </div>
        </div>
        <div style="position: relative; bottom: -20px; font-size: 64px">
          {{ 'that' }}<div class="colored ml-5" style="position: relative; font-size: 80px; display: inline-block;">{{ 'suits you' }}</div>
        </div>
        <div style="position: relative; font-size: 16px">
          {{ '각자에게 맞는 자판을 위해' }}
        </div>
      </div>
    </v-col>
    <!-- <v-col class="d-none d-md-flex section first" cols="12" md="3">
      <div style="position: absolute; top: 0; left: 0; width: 10px; height: 2px; background-color: #d5c4a1"></div>
      <div style="position: absolute; top: 2px; left: 0; width: 6px; height: 2px; background-color: #d5c4a1"></div>
      <div style="position: absolute; top: 4px; left: 0; width: 4px; height: 2px; background-color: #d5c4a1"></div>
      <div style="position: absolute; top: 6px; left: 0; width: 2px; height: 2px; background-color: #d5c4a1"></div>
      <div style="position: absolute; top: 8px; left: 0; width: 2px; height: 2px; background-color: #d5c4a1"></div>

      <div style="position: absolute; top: 0; right: 0; width: 10px; height: 2px; background-color: #d5c4a1"></div>
      <div style="position: absolute; top: 2px; right: 0; width: 6px; height: 2px; background-color: #d5c4a1"></div>
      <div style="position: absolute; top: 4px; right: 0; width: 4px; height: 2px; background-color: #d5c4a1"></div>
      <div style="position: absolute; top: 6px; right: 0; width: 2px; height: 2px; background-color: #d5c4a1"></div>
      <div style="position: absolute; top: 8px; right: 0; width: 2px; height: 2px; background-color: #d5c4a1"></div>

      <div style="position: absolute; bottom: 0; left: 0; width: 10px; height: 2px; background-color: #d5c4a1"></div>
      <div style="position: absolute; bottom: 2px; left: 0; width: 6px; height: 2px; background-color: #d5c4a1"></div>
      <div style="position: absolute; bottom: 4px; left: 0; width: 4px; height: 2px; background-color: #d5c4a1"></div>
      <div style="position: absolute; bottom: 6px; left: 0; width: 2px; height: 2px; background-color: #d5c4a1"></div>
      <div style="position: absolute; bottom: 8px; left: 0; width: 2px; height: 2px; background-color: #d5c4a1"></div>

      <div style="position: absolute; bottom: 0; right: 0; width: 10px; height: 2px; background-color: #d5c4a1"></div>
      <div style="position: absolute; bottom: 2px; right: 0; width: 6px; height: 2px; background-color: #d5c4a1"></div>
      <div style="position: absolute; bottom: 4px; right: 0; width: 4px; height: 2px; background-color: #d5c4a1"></div>
      <div style="position: absolute; bottom: 6px; right: 0; width: 2px; height: 2px; background-color: #d5c4a1"></div>
      <div style="position: absolute; bottom: 8px; right: 0; width: 2px; height: 2px; background-color: #d5c4a1"></div>

      <div style="width: 100%; text-align: center"></div>
    </v-col> -->

    <v-col class="section d-none d-sm-flex justify-center align-center pa-10" cols="12" md="4" style="flex-direction: column;">
      <div style="position: absolute; top: 0; left: 0; width: 10px; height: 2px; background-color: #d5c4a1"></div>
      <div style="position: absolute; top: 2px; left: 0; width: 6px; height: 2px; background-color: #d5c4a1"></div>
      <div style="position: absolute; top: 4px; left: 0; width: 4px; height: 2px; background-color: #d5c4a1"></div>
      <div style="position: absolute; top: 6px; left: 0; width: 2px; height: 2px; background-color: #d5c4a1"></div>
      <div style="position: absolute; top: 8px; left: 0; width: 2px; height: 2px; background-color: #d5c4a1"></div>

      <div style="position: absolute; top: 0; right: 0; width: 10px; height: 2px; background-color: #d5c4a1"></div>
      <div style="position: absolute; top: 2px; right: 0; width: 6px; height: 2px; background-color: #d5c4a1"></div>
      <div style="position: absolute; top: 4px; right: 0; width: 4px; height: 2px; background-color: #d5c4a1"></div>
      <div style="position: absolute; top: 6px; right: 0; width: 2px; height: 2px; background-color: #d5c4a1"></div>
      <div style="position: absolute; top: 8px; right: 0; width: 2px; height: 2px; background-color: #d5c4a1"></div>

      <div style="position: absolute; bottom: 0; left: 0; width: 10px; height: 2px; background-color: #d5c4a1"></div>
      <div style="position: absolute; bottom: 2px; left: 0; width: 6px; height: 2px; background-color: #d5c4a1"></div>
      <div style="position: absolute; bottom: 4px; left: 0; width: 4px; height: 2px; background-color: #d5c4a1"></div>
      <div style="position: absolute; bottom: 6px; left: 0; width: 2px; height: 2px; background-color: #d5c4a1"></div>
      <div style="position: absolute; bottom: 8px; left: 0; width: 2px; height: 2px; background-color: #d5c4a1"></div>

      <div style="position: absolute; bottom: 0; right: 0; width: 10px; height: 2px; background-color: #d5c4a1"></div>
      <div style="position: absolute; bottom: 2px; right: 0; width: 6px; height: 2px; background-color: #d5c4a1"></div>
      <div style="position: absolute; bottom: 4px; right: 0; width: 4px; height: 2px; background-color: #d5c4a1"></div>
      <div style="position: absolute; bottom: 6px; right: 0; width: 2px; height: 2px; background-color: #d5c4a1"></div>
      <div style="position: absolute; bottom: 8px; right: 0; width: 2px; height: 2px; background-color: #d5c4a1"></div>
      <v-btn class="btn-main pixelBorder pixelBorder--2" style="display: flex; position: absolute; top: 22px; bottom: 22px; left: 10px; right: 10px; height: auto; border-radius: 0; text-transform: none; flex-direction: column; color: #1a1a1a; pointer-events: none">
        <div style="position: relative; width: 200px">
          <v-img src="../images/logo_workshop_disabled.png" width="auto" height="60" style="margin-bottom: 5px"></v-img>
          <div style="position: relative; font-size: 32px; display: block;">{{ 'Workshop' }}</div>
          <div style="position: relative; font-size: 16px; display: block;">{{ '키보드곰방' }}</div>
        </div>
      </v-btn>
    </v-col>
    <v-col class="section d-none d-sm-flex justify-center align-center pa-10" cols="12" md="4" style="flex-direction: column;">
      <div style="position: absolute; top: 0; left: 0; width: 10px; height: 2px; background-color: #d5c4a1"></div>
      <div style="position: absolute; top: 2px; left: 0; width: 6px; height: 2px; background-color: #d5c4a1"></div>
      <div style="position: absolute; top: 4px; left: 0; width: 4px; height: 2px; background-color: #d5c4a1"></div>
      <div style="position: absolute; top: 6px; left: 0; width: 2px; height: 2px; background-color: #d5c4a1"></div>
      <div style="position: absolute; top: 8px; left: 0; width: 2px; height: 2px; background-color: #d5c4a1"></div>

      <div style="position: absolute; top: 0; right: 0; width: 10px; height: 2px; background-color: #d5c4a1"></div>
      <div style="position: absolute; top: 2px; right: 0; width: 6px; height: 2px; background-color: #d5c4a1"></div>
      <div style="position: absolute; top: 4px; right: 0; width: 4px; height: 2px; background-color: #d5c4a1"></div>
      <div style="position: absolute; top: 6px; right: 0; width: 2px; height: 2px; background-color: #d5c4a1"></div>
      <div style="position: absolute; top: 8px; right: 0; width: 2px; height: 2px; background-color: #d5c4a1"></div>

      <div style="position: absolute; bottom: 0; left: 0; width: 10px; height: 2px; background-color: #d5c4a1"></div>
      <div style="position: absolute; bottom: 2px; left: 0; width: 6px; height: 2px; background-color: #d5c4a1"></div>
      <div style="position: absolute; bottom: 4px; left: 0; width: 4px; height: 2px; background-color: #d5c4a1"></div>
      <div style="position: absolute; bottom: 6px; left: 0; width: 2px; height: 2px; background-color: #d5c4a1"></div>
      <div style="position: absolute; bottom: 8px; left: 0; width: 2px; height: 2px; background-color: #d5c4a1"></div>

      <div style="position: absolute; bottom: 0; right: 0; width: 10px; height: 2px; background-color: #d5c4a1"></div>
      <div style="position: absolute; bottom: 2px; right: 0; width: 6px; height: 2px; background-color: #d5c4a1"></div>
      <div style="position: absolute; bottom: 4px; right: 0; width: 4px; height: 2px; background-color: #d5c4a1"></div>
      <div style="position: absolute; bottom: 6px; right: 0; width: 2px; height: 2px; background-color: #d5c4a1"></div>
      <div style="position: absolute; bottom: 8px; right: 0; width: 2px; height: 2px; background-color: #d5c4a1"></div>

      <router-link to="/TypingBear" style="color: #fbf1c7; text-decoration: none;" @click="this.START()">
        <v-btn class="btn-main pixelBorder pixelBorder--2"
          v-on:mouseover="this.isHover = true"
          v-on:mouseleave="this.isHover = false">
          <div style="position: relative; width: 200px">
            <v-img :class="isHover ? 'btn-hover-img' : 'btn-img'" src="../images/logo_typingbear.png" width="auto" height="60" style="margin-bottom: 5px"></v-img>
            <span :class="isHover ? 'btn-span-big-hover' : 'btn-span-big'">{{ 'Typing Bear' }}</span>
            <span :class="isHover ? 'btn-span-small-hover' : 'btn-span-small'">{{ '자판 치는 곰돌이' }}</span>
          </div>
        </v-btn>
      </router-link>
    </v-col>
    <v-col class="section d-none d-sm-flex " cols="12" md="4">
      <div style="position: absolute; top: 0; left: 0; width: 10px; height: 2px; background-color: #d5c4a1"></div>
      <div style="position: absolute; top: 2px; left: 0; width: 6px; height: 2px; background-color: #d5c4a1"></div>
      <div style="position: absolute; top: 4px; left: 0; width: 4px; height: 2px; background-color: #d5c4a1"></div>
      <div style="position: absolute; top: 6px; left: 0; width: 2px; height: 2px; background-color: #d5c4a1"></div>
      <div style="position: absolute; top: 8px; left: 0; width: 2px; height: 2px; background-color: #d5c4a1"></div>

      <div style="position: absolute; top: 0; right: 0; width: 10px; height: 2px; background-color: #d5c4a1"></div>
      <div style="position: absolute; top: 2px; right: 0; width: 6px; height: 2px; background-color: #d5c4a1"></div>
      <div style="position: absolute; top: 4px; right: 0; width: 4px; height: 2px; background-color: #d5c4a1"></div>
      <div style="position: absolute; top: 6px; right: 0; width: 2px; height: 2px; background-color: #d5c4a1"></div>
      <div style="position: absolute; top: 8px; right: 0; width: 2px; height: 2px; background-color: #d5c4a1"></div>

      <div style="position: absolute; bottom: 0; left: 0; width: 10px; height: 2px; background-color: #d5c4a1"></div>
      <div style="position: absolute; bottom: 2px; left: 0; width: 6px; height: 2px; background-color: #d5c4a1"></div>
      <div style="position: absolute; bottom: 4px; left: 0; width: 4px; height: 2px; background-color: #d5c4a1"></div>
      <div style="position: absolute; bottom: 6px; left: 0; width: 2px; height: 2px; background-color: #d5c4a1"></div>
      <div style="position: absolute; bottom: 8px; left: 0; width: 2px; height: 2px; background-color: #d5c4a1"></div>

      <div style="position: absolute; bottom: 0; right: 0; width: 10px; height: 2px; background-color: #d5c4a1"></div>
      <div style="position: absolute; bottom: 2px; right: 0; width: 6px; height: 2px; background-color: #d5c4a1"></div>
      <div style="position: absolute; bottom: 4px; right: 0; width: 4px; height: 2px; background-color: #d5c4a1"></div>
      <div style="position: absolute; bottom: 6px; right: 0; width: 2px; height: 2px; background-color: #d5c4a1"></div>
      <div style="position: absolute; bottom: 8px; right: 0; width: 2px; height: 2px; background-color: #d5c4a1"></div>
    </v-col>
  </v-row>
</v-container>
</template>

<script>
import { mapMutations } from 'vuex'
export default {
  name: 'MainWindow',
  data () {
    return {
      workshopDisabled: true,
      isHover: false
    }
  },
  computed: {
    theme () {
      return (this.$vuetify.theme.dark) ? 'dark' : 'light'
    }
  },
  methods: {
    ...mapMutations([
      'START', 'STOP', 'NEXT_PLEASE', 'PAUSE', 'RESUME'
    ])
  },
  mounted () {}
}
</script>

<style lang="scss">

$base-color: #fbf1c7;
$light-base-color: #a89984;
$light-base-color2: #d5c4a1;
$brand-color: #fabd2f;
$green-color: #b8bb26;
$warning-color: #cc241d;
$contrast-color: #665c54;
$background-color: #1d2021;
$background-color2: #282828;
$background-color3: #3a3a39;

$brand-font: 'Ubuntu', sans-serif;
$neodgm-font: 'NeoDunggeunmo pro', sans-serif;
$serif-font: 'Iropke Batang', 'Iropke Batang', 'Noto Sans KR', 'Apple SD Gothic Neo', 'Malgun Gothic', serif;
$sanserif-font: 'Pretendard', sans-serif;
$base-font: 'Abel', serif;

$base-font-size: 24px;
$big-font-size: 32px;
$small-font-size: 16px;

$bold-weight: 400;

.main-container {
  // display: none;
  color: $base-color;

  @media all and (max-width: 600px) {
        padding: 0;
    }

  .section-container {
    border: 1px solid $light-base-color2;

    @media all and (max-width: 600px) {
        border: none;
    }

    .colored {
      background: linear-gradient(90deg, #ffc107, $green-color);
      background-clip: text;
      -webkit-background-clip: text;
      color: transparent;
      box-decoration-break: clone;
      -webkit-box-decoration-break: clone;

      filter: drop-shadow(-6px 0 0 black) drop-shadow(6px 0 0 black) drop-shadow(0 -6px 0 black) drop-shadow(0 6px 0 black);
    }

    .section.bg {
      background-image: url('~@/images/bg_keyboard_1.jpg');
      background-size: cover;
      background-position: center;
    }

    .section.first {
      min-height: 700px;

      @media all and (max-width: 600px) {
        min-height: calc(100vh - 95px);
      }
    }

    .section {
      position: relative;
      min-height: 300px;
      border: 1px solid $light-base-color2;
      background-color: #121212;

      @media all and (max-width: 600px) {
        min-height: 200px;
        border: none;
      }

      .btn-main {
          font-size: 32px;
          display: flex;
          position: absolute;
          top: 22px;
          left: 10px;
          bottom: 22px;
          right: 10px;
          height: auto;
          letter-spacing: 0;
          border-radius: 0;
          background-color: #282828;
          text-transform: none;
          flex-direction: column;
          transition: all 0.3s;

          img {
            display: inline-block !important;
          }
          .btn-img {
            transition: transform 0.3s;
          }
          .btn-hover-img {
            transition: transform 0.3s;
            transform: translateY(5px);
          }
          .btn-span-big, .btn-span-small {
            position: relative;
            font-size: 32px;
            display: block;
            transition: color 0.3s;
            color: $base-color;
          }
          .btn-span-small {
            font-size: 16px;
          }
          .btn-span-big-hover, .btn-span-small-hover {
            position: relative;
            font-size: 32px;
            display: block;
            color: white;
            transition: color 0.3s;
          }
          .btn-span-small-hover {
            font-size: 16px;
          }
        }
      .btn-main:hover {
          background-color: $background-color3;
      }
    }
  }

  @mixin pixelBorder($backgroundColor, $borderColor, $borderWidth: 2px) {
            box-shadow:
              // Background Color
              0             (-$borderWidth * 3)  0 (-$borderWidth)     $backgroundColor,
              0              ($borderWidth * 3)  0 (-$borderWidth)     $backgroundColor,
              0             (-$borderWidth * 6)  0 (-$borderWidth * 2) $backgroundColor,
              0              ($borderWidth * 6)  0 (-$borderWidth * 2) $backgroundColor,
              0             (-$borderWidth * 9)  0 (-$borderWidth * 4) $backgroundColor,
              0              ($borderWidth * 9)  0 (-$borderWidth * 4) $backgroundColor,
              0             (-$borderWidth * 12) 0 (-$borderWidth * 6) $backgroundColor,
              0              ($borderWidth * 12) 0 (-$borderWidth * 6) $backgroundColor,
              // Border
            (-$borderWidth)   0                  0   0                 $borderColor,
            ($borderWidth)   0                  0   0                 $borderColor,
              0             (-$borderWidth * 2)  0   0                 $borderColor,
              0              ($borderWidth * 2)  0   0                 $borderColor,
              0             (-$borderWidth * 5)  0 (-$borderWidth)     $borderColor,
              0              ($borderWidth * 5)  0 (-$borderWidth)     $borderColor,
              0             (-$borderWidth * 7)  0 (-$borderWidth * 2) $borderColor,
              0              ($borderWidth * 7)  0 (-$borderWidth * 2) $borderColor,
              0             (-$borderWidth * 10) 0 (-$borderWidth * 4) $borderColor,
              0              ($borderWidth * 10) 0 (-$borderWidth * 4) $borderColor,
              0             (-$borderWidth * 13) 0 (-$borderWidth * 6) $borderColor,
              0              ($borderWidth * 13) 0 (-$borderWidth * 6) $borderColor;
          }

          .pixelBorder {
            transform: translateZ(0); // Safari fix

            &--2 {
              @include pixelBorder($background-color2, $light-base-color);
            }

            &--4 {
              @include pixelBorder($background-color2, $light-base-color, 4px);
            }

            &--6 {
              @include pixelBorder($background-color2, $light-base-color, 6px);
            }

            &--10 {
              @include pixelBorder($background-color2, $light-base-color, 10px);
            }
          }

          .btn-main:hover.pixelBorder {
            transform: translateZ(0); // Safari fix

            &--2 {
              @include pixelBorder(#3c3c3a, $base-color);
            }

            &--4 {
              @include pixelBorder(#3c3c3a, $base-color, 4px);
            }

            &--6 {
              @include pixelBorder(#3c3c3a, $base-color, 6px);
            }

            &--10 {
              @include pixelBorder(#3c3c3a, $base-color, 10px);
            }
          }
}
</style>
