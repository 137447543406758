export default [
  {
    year: '1943',
    title: '어린 왕자',
    author: '앙투안 드 생텍쥐페리',
    content: '이 책을 어른에게 바치는데 대하여 어린이들에게 용서를 빈다. 여기에는 중요한 이유가 있다. 그것은 이 어른이 세상에서 나와 가장 친한 친구라는 것이다. 또 다른 한 이유는 이 어른이 나의 모든 점을 이해할 수 있고 어린이들을 위해 씌어진 책들까지도 이해할 수 있다는 것이다. 세 번째 이유는 이 어른이 프랑스에서 살고 있는데 그곳에서 그는 굶주리고 추위에 떨고 있다는 것이다. 이 어른을 잘 위로해 주어야 한다. 만일 이 모든 이유가 충분하지 않다면 나는 이 책을 이 어른이 옛날 어린이로 있던 시절에 기꺼이 바치고 싶다. 모든 어른들은 어린이였다. 그래서 나는 헌사를 이렇게 고쳐 쓴다.'
  },
  {
    year: '1931',
    title: '야간비행',
    author: '앙투안 드 생텍쥐페리',
    content: '비행기 아래로는 벌써 황금빛 석양 속으로 구릉의 그림자가 짙어져 밭고랑을 지듯 펼쳐졌고, 들판은 오래도록 쓰러지지 않을 빛으로 환하게 밝았다. 이 지방에서는 이울어가는 겨울에도 하얀 눈이 남아 있듯, 대지의 황금빛 저녁놀이 늦도록 불타올랐다.'
  },
  {
    year: '1929',
    title: '서부전선 이상없다',
    author: '레마르크',
    content: '여기까지 써 온 지원병 파울 보이머는 1918년 10월 어느 날 전사했다. 그날은 온 전선이 너무도 평온하고 조용하였으므로 군사보고서에는, "서부전선 이상 없음. 보고 사항 없음." 이라는 한 줄의 글로써 그쳐 있었다. 그는 앞으로 거꾸러져 땅 위에 잠자는 듯이 누워 있었다. 그의 몸을 뒤집어 보니, 오래 고통을 받았을 것 같지는 않았다. 그의 얼굴은 그렇게 된 것에 거의 만족이라도 하고 있는 것처럼 태연한 표정을 짓고 있었다.'
  },
  {
    year: '1945',
    title: '동물농장',
    author: '조지 오웰',
    content: '창 밖의 동물들은 돼지에서 인간으로, 인간에서 돼지로, 다시 돼지에서 인간으로 번갈아 시선을 옮겼다. 그러나 누가 돼지고 누가 인간인지, 어느 것이 어느 것인지 이미 분간할 수 없었다.'
  },
  {
    year: '1866',
    title: '죄와 벌',
    author: '표도르 도스토예프스키',
    content: '하지만 여기서 이미 새로운 이야기가, 한 인간이 점차 새로워지는 이야기이자 점차 다시 태어나는 이야기, 점차 하나의 세계에서 다른 세계로 옮겨가 여태껏 몰랐던 새로운 현실을 알아가는 이야기가 시작된다. 이것은 새로운 이야기의 주제가 될 수 있겠지만, \n -우리의 지금 이야기는 끝났다.'
  },
  {
    year: '1936',
    title: '메밀꽃 필 무렵',
    author: '이효석',
    content: '여름장이란 애시당초에 글러서, 해는 아직 중천에 있건만 장판은 벌써 쓸쓸하고 더운 햇발이 벌여놓은 전 휘장 밑으로 등줄기를 훅훅 볶는다. 마을 사람들은 거지 반 돌아간 뒤요, 팔리지 못한 나무꾼 패가 길거리에 궁싯거리고들 있으나 석유병이나 받고 고깃마리나 사면 족할 이 축들을 바라고 언제까지든지 버티고 있을 법은 없다. 춥춥스럽게 날아드는 파리 떼도 장난꾼 각다귀들도 귀치않다. 얽둑배기요 왼손잡이인 드팀전의 허 생원은 기어코 동업의 조 선달에게 나꾸어 보았다.'
  },
  {
    year: '1956',
    title: '최후의 질문',
    author: '아이작 아시모프',
    content: '빛이 있으라. 그러자 빛이 있었다.'
  },
  {
    year: '1859',
    title: '종의 기원',
    author: '찰스 다윈',
    content: '하나 혹은 적은 수의 생명체에 처음으로 생명이 깃들고 이 행성이 중력의 법칙에 따라 도는 동안 너무나도 간단한 기원으로부터 끝없는 생명들이 가장 아름답고, 가장 놀랍도록 존재해 왔고, 존재하고 있으며, 진화해 왔다. 이러한 생명관에는 장엄함이 있다.'
  },
  {
    year: '1605',
    title: '돈키호테',
    author: '미겔 데 세르반테스',
    content: '그다지 오래되지 않은 옛날, 라 만차 지방의 이름도 기억나지 않는 어느 마을에서 선반에 창과 낡은 방패를 두고, 야윈 말과 경주를 위한 사냥개를 가진 신사가 살고 있었다.'
  },
  {
    year: '1774',
    title: '젊은 베르테르의 슬픔',
    author: '요한 볼프강 폰 괴테',
    content: '훌쩍 떠나온 것이 나는 얼마나 기쁜지 모른다! 친구여, 인간의 마음이란 대체 어떤 것일까!'
  },
  {
    year: '1850',
    title: '주홍 글자',
    author: '너새니얼 호손',
    content: '거무스름한 빛깔의 옷차림에 끝이 뾰족한 회색 고깔모자를 쓰고 턱수염을 기른 사내들이 무리를 지어, 머리에 두건을 쓰기도 하고 쓰지 않기도 한 아낙네들과 뒤섞여 어느 목조건물 앞에 모여 있었다. 참나무로 튼튼하게 짠 문에는 장식용 무쇠 못이 군데군데 박혀 있었다.'
  },
  {
    year: '1851',
    title: '모비 딕',
    author: '허먼 멜빌',
    content: '나를 이스마엘이라 부르라.'
  },
  {
    year: '1859',
    title: '두 도시 이야기',
    author: '찰스 디킨스',
    content: '최고의 시절이자 최악의 시절, 지혜의 시대이자 어리석음의 시대였다. 믿음의 세기이자 의심의 세기였으며, 빛의 계절이자 어둠의 계절이었다. 희망의 봄이면서 곧 절망의 겨울이었다. 우리 앞에는 모든 것이 있었지만 한편으로 아무것도 없었다. 우리는 모두 천국으로 향해 가고자 했지만 우리는 엉뚱한 방향으로 걸었다. 말하자면, 지금과 너무 흡사하게, 그 시절 목청 큰 권위자들 역시 좋든 나쁘든 간에 오직 극단적인 비교로만 그 시대를 규정하려고 했다.'
  },
  {
    year: '1883',
    title: '보물섬',
    author: '로버트 루이스 스티븐슨',
    content: '대지주인 트렐로니 씨와 의사인 리브스 선생님을 비롯해 함께했던 다른 모든 신사분들이 내게 보물섬을 둘러싸고 일어난 일을 섬의 위치만 빼고 처음부터 끝까지 자세히 써보면 어떻겠냐고 권해 왔다. 섬의 위치를 빼자는 건 단지 아직 가져오지 못한 보물이 남아 있기 때문이다. 그리하여 서기 17XX년. 이제 나는 펜을 들어 아버지가 벤보우 제독 여관을 운영하던 날로, 구릿빛 얼굴에 칼자국이 난 그 늙은 뱃사람이 처음으로 우리 여관에 들어서던 그날로 돌아간다.'
  },
  {
    year: '1886',
    title: '지킬 박사와 하이드',
    author: '로버트 루이스 스티븐슨',
    content: '어터슨 변호사는 결코 환하게 웃는 법이 없는 무뚝뚝한 표정을 가진 사람이었다. 다른 사람들과 이야기를 나누는 중에도 그는 차가우면서도 어딘가 모자라고 당혹스러워하는 태도를 보였으며, 어쩐지 마지못해 말하는 것처럼 보였다. 그는 야윈 데다가 키가 크고 무미건조하고 음울해 보이기까지 했다. 그럼에도 어터슨에게는 어딘가 매력적인 부분이 있었다.'
  },
  {
    year: '1900',
    title: '오즈의 마법사',
    author: '라이먼 프랭크 바움',
    content: '도로시는 농부인 헨리 아저씨와 농부의 아내인 엠 아줌마와 함께 캔사스 중부의 대평원에 살고 있었다. 그들의 집은 작았고, 마차로 수 마일을 옮겨온 목재로 지어진 것이었다. 4개의 벽, 마루와 지붕이 하나의 방을 이루고 있다. 이 방에는 녹슨 요리용 스토브와 그릇들이 놓여진 선반 하나, 테이블 하나, 서너개의 의자, 그리고 침대들이 있었다. 헨리 아저씨와 엠 아주머니는 한쪽 구석의 큰 침대를 사용했고, 도로시는 다른 구석에 있는 작은 침대를 썼다. 다락방이나 지하실은 전혀 없었고, 바닥에는 지나가는 길에 있는 모든 건물을 부셔버리는 커다한 회오리 바람이 일어났을 때 온 가족이 피하기 위해 대피실로 쓰는 작은 구멍이 있었다. 바닥의 작은 문을 열고 사다리를 통해 작고 어두운 구멍으로 내려가면 되었다.'
  },
  {
    year: '1915',
    title: '변신',
    author: '프란츠 카프카',
    content: '어느 날 아침 그레고르 잠자가 편치 않은 꿈에서 깨어났을 때 그는 침대 속에서 한 마리의 엄청나게 큰 갑충으로 변해 있는 자신의 모습을 발견했다.'
  },
  {
    year: '1865',
    title: '이상한 나라의 앨리스',
    author: '루이스 캐럴',
    content: '앨리스는 언니와 함께 강둑에 앉아 아무 것도 하지 않고 있자니 점차 몹시 지루해졌다. 언니가 읽는 책을 한두 번 흘깃 보았는데 거기엔 그림도 없고 대화도 없었다. 앨리스는 "그림도 없고 대화도 없으면 책이 도대체 무슨 쓸모가 있는거지?"라고 생각했다. \n 그래서 앨리스는 일어나 데이지 꽃을 꺾는 수고로움이 데이지 꽃을 엮는 즐거움보다 클지 속으로 생각해보고 있었다. (뜨거운 날씨 때문에 몹시 졸리고 바보가 된 느낌이라서 당연히 그럴 수 있었을 것이다) 그 때, 분홍 빛 눈의 하얀 토끼 한 마리가 앨리스를 지나쳐 뛰어갔다.'
  }
  // {
  //   year: '2022',
  //   title: '테스트',
  //   author: '',
  //   content: '테스트입니다. \n 엔터 테스트'
  // }
]
