import Vuex from 'vuex'
import strComparison from './regex-matcher'
import Hangul from 'hangul-js'

export default new Vuex.Store({
  state: {
    input: '',
    content: '',
    year: '',
    author: '',
    title: '',
    isRunning: false,
    expressions: [],
    startTime: 0,
    seconds: 0,
    minutes: 0,
    timer: null,
    wrong: 0,
    correct: 0,
    index: 0
  },
  getters: {
    hasError (state) {
      return !strComparison(state.content, state.input)
    },
    typeWorldLength (state) {
      return state.input.length
    },
    vocabularyCharacters (state) {
      const content = state.content.split(' ')
      // content = state.content.split(' ')
      // console.warn(content)

      // const index = content.indexOf('↵')
      const contentLength = content.length

      // for (let index = 0; index < contentLength; index++) {
      //   if (content[index].indexOf('↵') > -1) {
      //     content[index] = content[index].split('↵')
      //   }
      // }

      // console.warn(content)

      for (let index = 0; index < contentLength; index++) {
        if (content[index + 1] !== '\n' && content[index] !== '\n' && index !== contentLength - 1) {
          content[index] = content[index] + ' '
        }
      }
      // console.error(content)
      return content
    },
    displayCharacters (state) {
      const content = state.content.split('')
      // content = content.split('')
      content.indexOf('\n')
      const contentLength = content.length
      for (let index = 0; index < contentLength; index++) {
        if (content[index] === '\n') {
          content.splice(index - 1, 1)
          content.splice(index, 1)
        }
      }
      return content
    },
    disassembleText (state) {
      const content = state.content.split('')
      // content = content.split('')
      content.indexOf('\n')
      const contentLength = content.length
      for (let index = 0; index < contentLength; index++) {
        if (content[index] === '\n') {
          content.splice(index - 1, 1)
          content.splice(index, 1)
        }
      }
      return Hangul.disassemble(content, true)
    },
    countdown (state) {
      return `${state.minutes}:${state.seconds}`
    }
  },
  mutations: {
    START (state) {
      if (!state.isRunning) {
        state.isRunning = true
        state.wrong = 0
        state.correct = 0
        this.commit('NEXT_PLEASE')
      }
    },
    STOP (state) {
      if (state.isRunning) {
        state.isRunning = false
        state.input = ''
        clearInterval(state.timer)
      }
    },
    NEXT_PLEASE (state) {
      if (state.input && !state.input.match(/next|start/)) {
        if (this.getters.hasError) {
          state.wrong++
        } else {
          state.correct++
        }
      }

      if ((state.index + 1) <= state.expressions.length) {
        state.content = state.expressions[state.index].content
        state.year = state.expressions[state.index].year
        state.author = state.expressions[state.index].author
        state.title = state.expressions[state.index].title
        state.index++
      } else {
        state.index = 0
        state.content = state.expressions[0].content
        state.year = state.expressions[0].year
        state.author = state.expressions[0].author
        state.title = state.expressions[0].title
      }
      // state.input = ''
    },
    START_TIMER (state, init) {
      if (!init) {
        state.startTime = new Date().getTime()
      }

      state.timer = setInterval(function () {
        const distance = (new Date().getTime() - state.startTime)
        state.minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
        state.seconds = Math.floor((distance % (1000 * 60)) / 1000)
      }, 1000)
    },
    RESUME (state) {
      if (!state.isRunning) {
        return
      }

      state.startTime = (new Date()).setSeconds(10)
      this.commit('START_TIMER', true)
      state.input = ''
    },
    PAUSE (state) {
      if (!state.isRunning) {
        return
      }

      state.input = ''
      clearInterval(state.timer)
    },
    SET_EXPRESSION (state, expressions) {
      state.expressions = expressions
    },
    SET_RANDOM_INDEX (state) {
      state.index = Math.floor((Math.random() * state.expressions.length))
    }
  }
})
