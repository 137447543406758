import App from './App.vue'
import store from './store'

import { createApp } from 'vue'
import router from './router'
import Trend from 'vue3trend'
import Vuex from 'vuex'

import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'

loadFonts()

createApp(App).use(Trend).use(Vuex).use(store).use(router).use(vuetify).mount('#app')
